import React from 'react'
import { createGlobalStyle } from 'styled-components'
import 'reset-css'
import '@fontsource/inter'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  body {
    background: #1C1C28;
    font-family: 'Inter', sans-serif;
  }
`

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <GlobalStyle />
      {element}
    </>
  )
}